import React, { useState } from "react";
import Header from "../components/Header";
import { CoinCard } from "../components/CoinCard";
import { NftCard } from "../components/NftCard";
import TokenType from "../components/TokenType";

export const Home = () => {
  const [showNftCard, setShowNftCard] = useState(true);

  return (
    <>
      <Header />

      <div className="my-24 w-screen flex items-center justify-center">
        <div className="max-w-xs md:max-w-5xl md:w-3/6 border-2 border-pink-400 bg-gray-900 p-8 shadow-lg shadow-gray-500 rounded-md text-white">
          <TokenType
            showNftCard={showNftCard}
            setShowNftCard={setShowNftCard}
          />

          {showNftCard ? <NftCard /> : <CoinCard />}
        </div>
      </div>
    </>
  );
};
