import Papa from "papaparse";
import hex from "string-hex";
import * as aptos from "aptos";
import { useEffect, useState } from "react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { GraphqlNode, CoinModuleAddr, getAptosClient } from "../apis.ts";
import { parseUnits } from "ethers/lib/utils";

export const CoinCard = () => {
  const { account, network, signAndSubmitTransaction } = useWallet();
  const [fileData, setFileData] = useState({ wallets: [], share: [] });
  const [user_coin_list, set_user_coin_list] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [pool_id, set_pool_id] = useState(null);
  const [pool_key, set_pool_key] = useState(null);
  const [isShowBox, setShowBox] = useState(true);
  const [displayData, setDisplayData] = useState("");
  const [parsedData, setParsedData] = useState([]);

  const getDisplayData = () => {
    if (parsedData.length > 0) {
      let text = "";
      parsedData.forEach((item) => {
        text += item.wallet + "," + item.share + "\n";
      });

      setDisplayData(text);
    }
  };

  useEffect(() => {
    getDisplayData();
  }, [parsedData]);

  const get_user_coins = async () => {
    const operationsDoc = `
    query MyQuery($wallet_address:String!){
      current_coin_balances(
        where: {owner_address: {_eq: $wallet_address}}
      ) {
        coin_info {
          coin_type
          decimals                                                              
          name
          symbol
        }
        amount
      }
    }
  `;

    const result = await fetch(GraphqlNode(network.name), {
      method: "POST",
      body: JSON.stringify({
        query: operationsDoc,
        variables: { wallet_address: account.address },
        operationName: "MyQuery",
      }),
    });

    const data = await result.json();
    const coinBalances = data.data.current_coin_balances;

    set_user_coin_list(coinBalances);
    setSelectedCoin(coinBalances[0]);
  };

  useEffect(() => {
    if (!account || !network) return;
    get_user_coins();
  }, [account]);

  const create_distributer_pool = async () => {
    try {
      const moduleAddr = CoinModuleAddr(network.name);
      const { wallets, share } = fileData;
      const walletsShare = share.map((_currShare) => {
        const _decimals = selectedCoin.coin_info.decimals;
        return parseUnits(_currShare, _decimals).toString();
      });

      const transaction = {
        function: `${moduleAddr}::initialize_multisender`,
        type_arguments: [selectedCoin.coin_info.coin_type],
        type: "entry_function_payload",
        arguments: [
          new aptos.HexString(hex(pool_key)).hex(),
          wallets,
          walletsShare,
          selectedCoin.coin_info.coin_type,
        ],
      };

      const pendingTransaction = await signAndSubmitTransaction(
        transaction
      );

      const client = getAptosClient(network.name);
      await client.waitForTransactionWithResult(pendingTransaction.hash);

      let resourc_val = await client.getAccountResource(
        account.address,
        `${moduleAddr}::SharedAccountEvent`
      );

      const result =
        resourc_val.data.resource_addr[
          resourc_val.data.resource_addr.length - 1
        ];

      set_pool_id(result);
      // alert(`Pools created with id ${result}`);
    } catch (err) {
      console.log(err);
      alert("Error creating pool");
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (!account) return alert("Please connect your wallet");

    let wallets = [];
    let share = [];
    let newArr = displayData.split("\n");
    newArr.forEach((elem) => {
      let arr = elem.split(",");
      wallets.push(arr[0]);
      share.push(arr[1]);
    });
    setFileData({ wallets, share });

    setShowBox(false);
  };

  const handleChange = (e) => {
    e.preventDefault();

    let selectedCoin = user_coin_list.filter(
      (coin) => coin.coin_info.name === e.target.value
    );

    if (selectedCoin.length > 0) {
      setSelectedCoin(selectedCoin[0]);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-3">
        {user_coin_list !== null ? (
          <div className="flex justify-between mt-2">
            <label htmlFor="coin" className="border-0">
              select a coin:
            </label>
            <select
              name="coin"
              id="coin"
              className="w-2/3 bg-gray-700 text-white"
              onChange={handleChange}
            >
              {user_coin_list.map((item, index) => {
                return (
                  <option
                    key={index}
                    value={item.coin_info.name}
                    className={"text-white"}
                  >
                    {item.coin_info.name} &nbsp;
                    {`${item.amount / 10 ** item.coin_info.decimals}`}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          <></>
        )}
        {isShowBox && (
          <>
            <div className="flex justify-between flex-wrap w-full mt-6 mb-3">
              <div>Enter Recipient Wallet and Amount</div>
              <div className="flex justify-around ">
                <span
                  style={{ cursor: "pointer" }}
                  className="text-white underline"
                >
                  <a href="../coin.csv" download="../coin.csv">
                    Sample CSV
                  </a>
                </span>
                <div>
                  <label
                    htmlFor="inputTag"
                    style={{ cursor: "pointer" }}
                    className="border-0 text-white bg-teal-500 p-1 ml-2"
                  >
                    Upload CSV
                    <input
                      id="inputTag"
                      style={{ display: "none" }}
                      type="file"
                      onChange={(e) => {
                        const reader = new FileReader();
                        reader.onload = async ({ target }) => {
                          const csv = Papa.parse(target.result.trim(), {
                            header: true,
                          });
                          const parsedData = csv?.data;
                          setParsedData(parsedData);
                          let wallets = [];
                          let share = [];

                          parsedData.forEach((element) => {
                            wallets.push(element.wallet);
                            share.push(element.share);
                          });

                          setFileData({
                            wallets,
                            share,
                          });

                          setShowBox(false);
                        };
                        reader.readAsText(e.target.files[0]);
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
            <form
              id="form"
              className="flex flex-col"
              onSubmit={onSubmitHandler}
            >
              <textarea
                className="border-2 border-black bg-gray-700"
                name="data"
                id="data"
                value={displayData}
                rows={5}
                placeholder="wallet,amount"
                onChange={(e) => setDisplayData(e.target.value)}
              ></textarea>
              <input
                type="submit"
                value="Submit"
                className="bg-teal-500 m-4 p-2 rounded-xl w-32 place-self-center text-xl text-white cursor-pointer hover:buttonB"
              />
            </form>
          </>
        )}
      </div>

      {fileData.wallets.length > 0 && !isShowBox && (
        <div className="w-full flex flex-col justify-center">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Wallet</th>
                <th>Share</th>
              </tr>
            </thead>

            <tbody>
              {fileData.wallets.map((wallet, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <p className="break-all">{wallet}</p>
                    </td>
                    <td>{fileData.share[index]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button
            className="bg-[#1976d2] m-4 px-8 rounded-xl  place-self-center text-xl text-white cursor-pointer hover:buttonB"
            onClick={() => setShowBox(true)}
          >
            edit
          </button>
        </div>
      )}

      <div className="flex justify-center lg:justify-between items-center flex-wrap">
        <input
          className="w-1/2 bg-gray-700"
          style={{
            margin: "20px 10px",
            padding: "10px",
          }}
          type="text"
          placeholder="Enter pool key (remember this key)"
          onChange={(e) => {
            e.target.value !== null && set_pool_key(e.target.value);
          }}
        />

        <button
          className="text-white bg-teal-500 border-0 uppercase"
          onClick={create_distributer_pool}
        >
          create
        </button>
      </div>

      {pool_id !== null && <p className="break-all">COPY POOL ID: {pool_id}</p>}
    </>
  );
};
