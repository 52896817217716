export default function TokenType({ showNftCard, setShowNftCard }) {
  return (
    <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
      <li className="mr-2">
        <button
          aria-current="page"
          className={`inline-block p-4 rounded-t-lg 
          text-white bg-pink-500 ${
            showNftCard ? "bg-teal-500 text-white cursor-default" : "opacity-50"
          }`}
          onClick={() => setShowNftCard(true)}
        >
          NON FUNGIBLE TOKENS (NFT)
        </button>
      </li>
      <li className="mr-2">
        <button
          className={`inline-block p-4 rounded-t-lg  bg-pink-500
          text-white
         ${
           showNftCard ? "opacity-50" : "bg-teal-500 text-white cursor-default"
         }`}
          onClick={() => setShowNftCard(false)}
        >
          NATIVE / STANDARD COINS
        </button>
      </li>
    </ul>
  );
}
