import { AptosClient } from "aptos";

declare global {
  interface Window {
    aptos: any;
  }
}

export const getAptosClient = (_network: string) => {
  if (_network.toLowerCase() === "mainnet")
    return new AptosClient("https://fullnode.mainnet.aptoslabs.com");
  if (_network.toLowerCase() === "testnet")
    return new AptosClient("https://fullnode.testnet.aptoslabs.com");
  throw new Error("Invalid network");
};

export const CoinModuleAddr = (_network: string) => {
  if (_network.toLowerCase() === "mainnet")
    return "0x2d525e8fa9cb2da9ad8194e4b254fa3483ca58a19b90372025d47578625c0dac::multisender_coin_v1";
  if (_network.toLowerCase() === "testnet")
    return "0x2d525e8fa9cb2da9ad8194e4b254fa3483ca58a19b90372025d47578625c0dac::multisender_coin_v1";
  return "";
};

export const NftModuleAddr = (_network: string) => {
  if (_network.toLowerCase() === "mainnet")
    return "0x2d525e8fa9cb2da9ad8194e4b254fa3483ca58a19b90372025d47578625c0dac::multisender_nft_v1";
  if (_network.toLowerCase() === "testnet")
    return "0x2d525e8fa9cb2da9ad8194e4b254fa3483ca58a19b90372025d47578625c0dac::multisender_nft_v1";
  return "";
};

export const GraphqlNode = (_network: string) => {
  if (_network.toLowerCase() === "mainnet")
    return "https://indexer.mainnet.aptoslabs.com/v1/graphql";
  if (_network.toLowerCase() === "testnet")
    return "https://indexer-testnet.staging.gcp.aptosdev.com/v1/graphql";
  return "";
};
