import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import Button1 from "./Button1";

const Header = () => {
  const [menu, showMenu] = useState(false);
  const [toggleWallets, setToggleWallets] = useState(false);

  const { connect, account, network, connected, disconnect, wallets } =
    useWallet();

  return (
    <nav className="bg-black bg-gradient-to-l from-gray-700 to-[#000000] p-2 pl-4 pr-4 flex flex-col md:flex-row justify-between items-center header gap-4 text-white">
      <div className="w-36">
        <img src="/logo.png" width="100%" alt="logo" />
      </div>
      <div className="md:hidden relative w-full flex flex-wrap justify-center items-center">
        <button
          className="md:hidden bg-inherit p-4 w-24 border-0"
          onClick={() => {
            menu ? showMenu(false) : showMenu(true);
          }}
        >
          {menu ? (
            <i className="fa-solid fa-xmark text-white text-4xl"></i>
          ) : (
            <i className="fa-sharp fa-solid fa-bars text-white text-4xl"></i>
          )}
        </button>

        {menu && (
          <div
            className={`top-20 absolute flex flex-col md:hidden justify-around items-center border-2 border-white bg-gray-800 md:border-0 w-1/2 text-xl gap-2 p-4 z-50`}
          >
            <div className="w-full text-center ">
              <Link to="/">Create</Link>
            </div>
            <div className="w-full text-center">
              <Link to="/claim">Claim</Link>
            </div>
            <div className="w-full text-center">
              <Link to="/manage">Manage</Link>
            </div>
          </div>
        )}
      </div>

      <div
        className={`hidden md:flex flex-col md:flex-row justify-around items-center border-2 border-red-300 md:border-0 w-1/2 text-xl gap-2`}
      >
        <Link to="/">Create</Link>
        <Link to="/claim">Claim</Link>
        <Link to="/manage">Manage</Link>
      </div>

      {network ? (
        <Button1 onClick={() => alert("Switch network inside your Wallet")}>
          {network.name.toUpperCase()}
        </Button1>
      ) : null}
      <div>
        {connected ? (
          <Button1 onClick={disconnect}>
            {`${account.address.slice(0, 6)}...${account.address.slice(
              38,
              42
            )}`}
          </Button1>
        ) : (
          <div>
            <div>
              <Button1
                className="text-white bg-pink-500"
                onClick={() => setToggleWallets(!toggleWallets)}
              >
                Connect Wallet
              </Button1>
            </div>

            {toggleWallets ? (
              <ul className="absolute">
                {wallets.map((wallet, idx) => (
                  <li
                    className="ml-5 rounded cursor-pointer text-base border-b p-1 m-1"
                    key={idx}
                    onClick={() => connect(wallet.name)}
                  >
                    {wallet.name}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
