import {
  getAptosClient,
  CoinModuleAddr,
  NftModuleAddr,
  GraphqlNode,
} from "../apis.ts";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useWallet } from "@aptos-labs/wallet-adapter-react";

export const ManagePoolCard = () => {
  const { account, network, signAndSubmitTransaction } = useWallet();
  const [isPoolsloading, setPoolsLoading] = useState(false);
  const [userCoinPools, setUserCoinPools] = useState(null);
  const [empty_type, set_empty_type] = useState(null);
  const [claim_pool_id, set_claim_pool_id] = useState(null);

  const get_user_coin_pool = async () => {
    if (!account) return;
    try {
      setPoolsLoading(true);
      const user = account.address;
      const client = getAptosClient(network.name);
      const CONTRACT_ADDRESS = CoinModuleAddr(network.name);
      const GRAPH_QL_NODE = GraphqlNode(network.name);

      const pools = await client.getAccountResource(
        user,
        `${CONTRACT_ADDRESS}::SharedAccountEvent`
      );

      let pool_data = [];

      for (let i = 0; i < pools.data.resource_addr.length; i++) {
        let pool_address = pools.data.resource_addr[i];
        const operationsDoc = `
          query MyQuery($pool_address:String!) {
            current_coin_balances(
              where: {owner_address: {_eq: $pool_address}}
            ) {
              amount
              coin_type
              coin_info {
                decimals
                symbol
              }
            }
          }
        `;

        const result = await fetch(GRAPH_QL_NODE, {
          method: "POST",
          body: JSON.stringify({
            query: operationsDoc,
            variables: { pool_address },
            operationName: "MyQuery",
          }),
        });

        const data = await result.json();

        let coin_data = [];

        data.data.current_coin_balances.forEach((item) => {
          coin_data.push({
            amount: item.amount,
            coin_type: item.coin_type,
            symbol: item.coin_info.symbol,
            decimals: item.coin_info.decimals,
          });
        });

        pool_data.push({ coin_data, pool_address });
      }

      setUserCoinPools(pool_data);
    } catch (err) {
      console.log(err);
    }
    setPoolsLoading(false);
  };

  const cancel_coin_pool = async (pool_addr) => {
    if (!empty_type) return alert('Select Coins on left side of "Cancel Pool"');
    try {
      const client = getAptosClient(network.name);
      const CONTRACT_ADDRESS = CoinModuleAddr[network.name];

      const transaction = {
        function: `${CONTRACT_ADDRESS}::withdraw_remaining_pool_balance`,
        type_arguments: [empty_type],
        type: "entry_function_payload",
        arguments: [pool_addr],
      };

      const pendingTransaction = await signAndSubmitTransaction(
        transaction
      );

      await client.waitForTransactionWithResult(pendingTransaction.hash);
      alert("Pool Emptied");
    } catch (err) {
      console.log(err);
      alert("Error occured");
    }
  };

  useEffect(() => {
    get_user_coin_pool();
  }, [account, network]);

  const cancel_nft_pool = async (pool_id) => {
    if (!account) return alert("Wallet is not connected");
    try {
      const client = getAptosClient(network.name);
      const CONTRACT_ADDRESS = NftModuleAddr(network.name);

      const transaction = {
        function: `${CONTRACT_ADDRESS}::cancel_all_unclaimed_offer`,
        type_arguments: [],
        type: "entry_function_payload",
        arguments: [pool_id],
      };

      const pendingTransaction = await signAndSubmitTransaction(
        transaction,
        { gas: 200000000 }
      );

      await client.waitForTransactionWithResult(pendingTransaction.hash);

      alert("Cancelled NFT Pool");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="flex justify-center lg:justify-between items-center flex-wrap mt-4">
        <input
          type="text"
          placeholder="Enter Pool Id"
          className="w-1/2 bg-gray-700"
          style={{
            margin: "20px 10px",
            padding: "10px",
          }}
          onChange={(e) => {
            set_claim_pool_id(e.target.value);
          }}
        />
        <button
          className="text-white transition-transform hover:scale-110 bg-teal-500 hover:buttonB border-0 uppercase"
          onClick={() => cancel_nft_pool(claim_pool_id)}
        >
          Cancel NFT Pool
        </button>
      </div>
      {isPoolsloading && account ? (
        <div className="flex flex-col justify-center  items-center">
          <h1 className="text-center">Fetching Coin-pools</h1>
          <RotatingLines
            strokeColor="rgb(25 118 210 / 1)"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : null}
      {userCoinPools !== null && (
        <div>
          <hr className="border-1 border-white" />
          <h1 className="text-center text-xl font-bold mt-4">
            Your Coin Pools
          </h1>
          <table className="flex flex-col justify-center item-center">
            <tbody>
              {userCoinPools.map((item, index) => {
                return (
                  <div key={index} className="flex flex-col">
                    <tr>
                      <p className="break-words border-0">
                        {item.pool_address}
                      </p>

                      <div
                        className="flex flex-col md:flex-row flex-wrap
                      justify-start md:justify-center items-center"
                      >
                        {item.coin_data.map((item) => (
                          <div className="flex flex-col md:flex-row justify-center items-center">
                            <button
                              className="btn text-white"
                              onClick={() => {
                                set_empty_type(item.coin_type);
                              }}
                              style={{
                                background:
                                  empty_type === item.coin_type
                                    ? "#4500ee"
                                    : "",
                              }}
                            >{`${item.symbol} Balance::${
                              item.amount / 10 ** item.decimals
                            }`}</button>
                          </div>
                        ))}
                        <div className="">
                          <button
                            className="btn text-white px-6"
                            onClick={() => cancel_coin_pool(item.pool_address)}
                          >
                            Cancel Pool
                          </button>
                        </div>
                      </div>
                    </tr>
                    <hr className="border-2 border-gray-500" />
                  </div>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
