import React from "react";
import Header from "../components/Header";

import { ManagePoolCard } from "../components/ManagePoolCard";

export const Manage = () => {
  return (
    <>
      <Header />

      <div className="my-24 w-screen flex items-center justify-center">
        <div className="max-w-xs md:max-w-5xl md:w-3/6 border-2 border-pink-400 bg-gray-900 p-8 shadow-lg shadow-gray-500 rounded-md text-white">
          <ManagePoolCard />
        </div>
      </div>
    </>
  );
};
