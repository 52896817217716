import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { getAptosClient, NftModuleAddr, GraphqlNode } from "../apis.ts";
import { useWallet } from "@aptos-labs/wallet-adapter-react";

export default function ClaimNFTCard() {
  const { account, network, signAndSubmitTransaction } = useWallet();
  const [accept_pool_id, set_accept_pool_id] = useState(null);
  const [user_data, set_user_data] = useState(null);
  const [isInfo, setInfo] = useState(false);

  const get_nft_data = async () => {
    if (!account || !accept_pool_id) return;
    try {
      const pool_id = accept_pool_id;
      const connected_user = account.address;
      const CONTRACT_ADDRESS = NftModuleAddr(network.name);
      const client = getAptosClient(network.name);

      set_user_data("Loading..");

      let resourc_val = await client.getAccountResource(
        pool_id,
        `${CONTRACT_ADDRESS}::Pool`
      );

      const index = resourc_val.data.receiver_list.indexOf(connected_user);

      if (index === -1) {
        return null;
      } else {
        let receiver_data = resourc_val.data.pool_offer.data[index];

        const filtered = resourc_val.data.pool_offer.data.filter((item) => {
          return item.key === connected_user;
        });
        receiver_data = filtered[0];

        const operationsDoc = `
            query MyQuery($name:String!,$collection:String!) {
              token_datas(
                where: {name: {_eq: $name}, collection_name: {_eq: $collection}}
              ) {
                metadata_uri
              }
            }
        `;

        const result = await fetch(GraphqlNode(network.name), {
          method: "POST",
          body: JSON.stringify({
            query: operationsDoc,
            variables: {
              name: receiver_data.value.name,
              collection: receiver_data.value.collection,
            },
            operationName: "MyQuery",
          }),
        });
        const result_data = await result.json();

        const required_data = {
          collection: receiver_data.value.collection,
          tokenname: receiver_data.value.name,
          uri: result_data.data.token_datas[0].metadata_uri,
          has_claimed: receiver_data.value.has_claimed,
          is_cancelled: receiver_data.value.is_cancelled,
        };
        if (required_data === null) {
          set_user_data("No NFT..");
        } else {
          set_user_data(required_data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get_nft_data();
  }, [account, network]);

  const accept_nft_claim = async () => {
    const pool_id = accept_pool_id;

    try {
      const CONTRACT_ADDRESS = NftModuleAddr(network.name);
      const client = getAptosClient(network.name);

      const transaction = {
        function: `${CONTRACT_ADDRESS}::claim_pool_offer`,
        type_arguments: [],
        type: "entry_function_payload",
        arguments: [pool_id],
      };

      const pendingTransaction = await signAndSubmitTransaction(
        transaction
      );

      await client.waitForTransactionWithResult(pendingTransaction.hash);
      alert("NFT Claimed successfully");
    } catch (err) {
      console.log(err);
      alert("Got Error while accepting NFT Claim");
    }
  };

  return (
    <>
      <div className="flex justify-center lg:justify-between items-center flex-wrap  mt-4">
        <input
          className="w-1/2 bg-gray-700"
          type="text"
          placeholder="Enter Pool Id"
          style={{
            margin: "20px 10px",
            padding: "10px",
          }}
          onChange={(e) => {
            set_accept_pool_id(e.target.value);
          }}
        />

        <div className="flex justify-center items-center">
          {isInfo ? (
            <button
              className="w-40 text-white transition-transform hover:scale-110 hover:buttonB"
              onClick={accept_nft_claim}
            >
              Accept Claim
            </button>
          ) : (
            <button
              className="w-40 text-white transition-transform hover:scale-110 bg-teal-500 hover:buttonB border-0"
              onClick={async () => {
                if (!account) {
                  alert("Please connect your wallet");
                } else {
                  setInfo(true);
                }
              }}
            >
              CLAIM NFT
            </button>
          )}
        </div>
      </div>

      <div>
        {user_data !== null ? (
          user_data === "Loading.." ? (
            <div className="flex justify-center items-center">
              <RotatingLines
                strokeColor="rgb(25 118 210 / 1)"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          ) : user_data === "No NFT.." ? (
            "No NFT found for the current user"
          ) : (
            <div className="flex justify-center items-center flex-wrap bg-gray-600-100">
              <div className="w-60 h-56">
                <img src={user_data.uri} alt="nft" className="w-full h-full" />
              </div>

              <div className="flex flex-col justify-center items-center">
                <p>Token: {user_data.tokenname}</p>
                <p>Collection: {user_data.collection}</p>
                <p>has claimed: {user_data.has_claimed ? "Yes" : "No"}</p>
                <p>
                  offer valid:{" "}
                  {user_data.is_cancelled
                    ? "No"
                    : user_data.has_claimed
                    ? "No"
                    : "Yes"}
                </p>
              </div>
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
}
