import React, {  useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { getAptosClient, CoinModuleAddr, GraphqlNode } from "../apis.ts";
import { useWallet } from "@aptos-labs/wallet-adapter-react";

export default function ClaimCoinCard() {
  const { account, network, signAndSubmitTransaction } = useWallet();

  const [claim_data, set_claim_data] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pool_id2, set_pool_id2] = useState(null);

  const get_climer_status = async () => {
    try {
      if (!account) return;

      set_claim_data(null);
      setLoading(true);

      const client = getAptosClient(network.name);
      const _moduleAddr = CoinModuleAddr(network.name);

      let resource = await client.getAccountResource(
        pool_id2,
        `${_moduleAddr}::SharedAccount`
      );
      let current_data = null;

      let coin_type = resource.data.coin_name;

      const operationsDoc = `
        query MyQuery($coin_type:String!) {
          coin_infos(where: {coin_type: {_eq:$coin_type }}) {
            name
            decimals
            symbol
          }
        }
      `;

      const result = await fetch(GraphqlNode(network.name), {
        method: "POST",
        body: JSON.stringify({
          query: operationsDoc,
          variables: { coin_type },
          operationName: "MyQuery",
        }),
      });

      const data = await result.json();

      resource.data.share_record.forEach((element) => {
        if (element.share_holder === account.address) {
          current_data = element;
        }
      });

      const finalResult = {
        ...current_data,
        name: data.data.coin_infos[0].name,
        symbol: data.data.coin_infos[0].symbol,
        decimals: data.data.coin_infos[0].decimals,
        coin_type: coin_type,
      };
      set_claim_data(finalResult);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const claim_coins = async () => {
    try {
      const client = getAptosClient(network.name);
      const _moduleAddr = CoinModuleAddr(network.name);
      const { coin_type } = claim_data;

      const transaction = {
        function: `${_moduleAddr}::claim_coin`,
        type_arguments: [coin_type],
        type: "entry_function_payload",
        arguments: [pool_id2, coin_type],
      };

      const pendingTransaction = await signAndSubmitTransaction(
        transaction
      );

      await client.waitForTransactionWithResult(pendingTransaction.hash);
      alert("Coin Claimed Successfully");
    } catch (err) {
      console.log(err);
      alert("Coin Claim Failed");
    }
  };

  return (
    <>
      <div className="flex justify-center lg:justify-between items-center flex-wrap  mt-4">
        <input
          className="w-1/2 bg-gray-700"
          style={{
            margin: "20px 10px",
            padding: "10px",
          }}
          type="text"
          placeholder="Enter Pool Id"
          onChange={(e) => {
            e.target.value !== null && set_pool_id2(e.target.value);
          }}
        />
        <button
          className="w-40 text-white transition-transform hover:scale-110 bg-teal-500 hover:buttonB border-0"
          onClick={get_climer_status}
        >
          CLAIM COIN
        </button>
      </div>
      {loading && (
        <div className="flex justify-center items-center">
          <RotatingLines
            strokeColor="rgb(25 118 210 / 1)"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
      {claim_data !== null ? (
        <div className="flex flex-col justify-center items-center">
          {" "}
          {isNaN(claim_data.num_shares / 10 ** claim_data.decimals) ? (
            <>
              <h3>user: Not Available</h3>
              <h3>user share: Not Available</h3>
            </>
          ) : (
            <>
              <p className="break-all">user: {claim_data.share_holder}</p>
              <h3>
                user share: {claim_data.num_shares / 10 ** claim_data.decimals}{" "}
                {claim_data.symbol}
              </h3>
            </>
          )}
          <h3>
            claim status:{" "}
            {claim_data.has_claimed === true ? "Claimed" : "Not Claimed"}
          </h3>
          <h3>coin reward: {claim_data.name}</h3>
          {claim_data.has_claimed === true ? (
            <button className="text-white">Already Claimed</button>
          ) : (
            <button className="text-white" onClick={claim_coins}>
              Claim
            </button>
          )}
        </div>
      ) : null}
    </>
  );
}
