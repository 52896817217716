import React from "react";
import Footer from "./components/Footer";
import { Home } from "./pages";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Claim } from "./pages/claim";
import { Manage } from "./pages/manage";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { RiseWallet } from "@rise-wallet/wallet-adapter";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";
import { PontemWallet } from "@pontem/wallet-adapter-plugin";
import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import "./index.css";

export const App = () => {
  const wallets = [
    new PetraWallet(),
    new RiseWallet(),
    new MartianWallet(),
    new PontemWallet(),
  ];

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "auto 1fr auto",
          gridTemplateAreas: `'header' 'main' 'footer'`,
          minHeight: "100vh",
        }}
      >
        <AptosWalletAdapterProvider plugins={wallets} autoConnect={true}>
          <Router>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/claim" element={<Claim />}></Route>
              <Route path="/manage" element={<Manage />}></Route>
            </Routes>

            <Footer />
          </Router>
        </AptosWalletAdapterProvider>
      </div>
    </>
  );
};
