import Papa from "papaparse";
import { GraphqlNode, NftModuleAddr, getAptosClient } from "../apis.ts";
import React, { useEffect, useState } from "react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { HexString } from "aptos";
import hex from "string-hex";

export const NftCard = () => {
  const { account, network, signAndSubmitTransaction } = useWallet();

  const [fileData, setFileData] = useState(null);
  const [pool_string, set_pool_string] = useState(null);
  const [pool_id, set_pool_id] = useState(null);

  const [isShowBox, setShowBox] = useState(true);
  const [displayData, setDisplayData] = useState("");
  const [parsedData, setParsedData] = useState([]);
  const [userNfts, setUserNfts] = useState([]);
  const [totalSelected, setTotalSelected] = useState(0);

  const selectAll0 = (isSelected) => {
    const newArr = userNfts.map((item) => {
      const nfts = item.nfts.map((nft) => {
        nft.isSelected = isSelected;
        return nft;
      });
      item.nfts = nfts;
      return item;
    });
    setUserNfts(newArr);
  };

  useEffect(() => {
    try {
      let total = 0;
      for (let i = 0; i < userNfts.length; i++) {
        const _nfts = userNfts[i].nfts;
        for (let j = 0; j < _nfts.length; j++) {
          if (_nfts[j].isSelected) total++;
        }
      }
      setTotalSelected(total);
    } catch (err) {
      console.log(err);
    }
  }, [userNfts]);

  const getDisplayData = () => {
    if (parsedData.length > 0) {
      let text = "";
      parsedData.forEach((item) => {
        text += item.receiver + "\n";
      });

      setDisplayData(text);
    }
  };

  useEffect(() => {
    getDisplayData();
  }, [parsedData]);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (!account) return alert("Please connect your wallet");

    const newArr = displayData.split("\n");

    const receiverArr = newArr.map((item) => {
      return {
        receiver: item,
      };
    });

    setFileData(receiverArr);
    setShowBox(false);
  };

  const fetchImageUrl = async (uri) => {
    try {
      if (
        uri.endsWith(".png") ||
        uri.endsWith(".jpg") ||
        uri.endsWith(".jpeg") ||
        uri.endsWith(".svg")
      )
        return uri;
      const resp0 = await fetch(uri);
      const resp1 = await resp0.json();
      return resp1.image;
    } catch (err) {
      return uri;
    }
  };

  const get_user_nfts = async () => {
    const user = account.address;

    const operationsDoc = `
    query MyQuery($user:String!) {
      current_token_ownerships(
        where: {owner_address: {_eq: $user}, amount: {_eq: "1"}}
      ) {
        current_token_data {
          creator_address
          metadata_uri
          name
          collection_name
          properties_mutable
        }
        property_version
      }
    }
  `;

    const result = await fetch(GraphqlNode(network.name), {
      method: "POST",
      body: JSON.stringify({
        query: operationsDoc,
        variables: { user },
        operationName: "MyQuery",
      }),
    });

    const token_list_result = await result.json();

    const nft_collection = {};

    token_list_result.data.current_token_ownerships.forEach((item) => {
      if (
        nft_collection[item.current_token_data.collection_name] === undefined
      ) {
        nft_collection[`${item.current_token_data.collection_name}`] = [item];
      } else {
        nft_collection[`${item.current_token_data.collection_name}`].push(item);
      }

      // item.current_token_data.collection_name;
    });

    return nft_collection;
  };

  async function getusernfts() {
    try {
      if (account === null) return;
      const userCollections = await get_user_nfts();
      const cArr = Object.keys(userCollections);

      const collectionArr = [];
      for (let i = 0; i < cArr.length; i++) {
        const collection1 = {};
        const _cName = cArr[i];
        collection1.name = _cName;

        const _uriPromiseArr = [];
        for (let j = 0; j < userCollections[_cName].length; j++) {
          const nftObj = userCollections[_cName][j];
          _uriPromiseArr.push(
            fetchImageUrl(nftObj.current_token_data.metadata_uri)
          );
        }
        const _imageUriArr = await Promise.all(_uriPromiseArr);
        for (let j = 0; j < userCollections[_cName].length; j++) {
          userCollections[_cName][j].current_token_data.metadata_uri =
            _imageUriArr[j];
          userCollections[_cName][j].isSelected = false;
        }

        collection1.nfts = userCollections[_cName];
        collectionArr.push(collection1);
      }
      setUserNfts(collectionArr);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getusernfts();
  }, [account, network]);

  const create_nft_pool = async () => {
    try {
      if (!account.address) return alert("Please connect your wallet");

      const _selectedNFTs = [];
      for (let i = 0; i < userNfts.length; i++) {
        const _nfts = userNfts[i].nfts;
        for (let j = 0; j < _nfts.length; j++) {
          if (_nfts[j].isSelected) {
            _selectedNFTs.push(_nfts[j]);
          }
        }
      }

      if (fileData.length !== _selectedNFTs.length)
        return alert("number of receiver is not same as number of nfts");

      const all_nfts = _selectedNFTs;
      const all_receivers = fileData;
      const pool_key = pool_string;
      const connected_user = account.address;
      const CONTRACT_ADDRESS = NftModuleAddr(network.name);
      const client = getAptosClient(network.name);

      let all_receiver = [];
      let all_creator = [];
      let all_tokenname = [];
      let all_collection = [];
      let all_property = [];

      for (let i = 0; i < all_receivers.length; i++) {
        all_receiver.push(all_receivers[i].receiver);
        all_creator.push(all_nfts[i].current_token_data.creator_address);
        all_tokenname.push(all_nfts[i].current_token_data.name);
        all_collection.push(all_nfts[i].current_token_data.collection_name);
        all_property.push(all_nfts[i].property_version);
      }

      shuffle(all_receiver);

      const transaction = {
        function: `${CONTRACT_ADDRESS}::create_nft_pool_offers`,
        type_arguments: [],
        type: "entry_function_payload",
        arguments: [
          all_receiver,
          all_creator,
          all_tokenname,
          all_collection,
          all_property,
          new HexString(hex(pool_key)).hex(),
        ],
      };

      const pendingTransaction = await signAndSubmitTransaction(
        transaction
      );

      await client.waitForTransactionWithResult(pendingTransaction.hash);

      let resourc_val = await client.getAccountResource(
        connected_user,
        `${CONTRACT_ADDRESS}::PoolCreatedEvents`
      );

      const _poolId = resourc_val.data.pool_addr.slice(-1)[0];
      set_pool_id(_poolId);
      // alert(`Pool created with id : ${_poolId}`);
    } catch (err) {
      console.log(err);
      alert("Got Error while creating pool");
    }
  };

  return (
    <>
      <div>
        <SelectBox sAmount={totalSelected} selectAll={selectAll0} />
        {userNfts.length
          ? userNfts.map((item, collectionIdx) => (
              <Collection1
                key={collectionIdx}
                collection={item}
                collectionIdx={collectionIdx}
                userNfts={userNfts}
                setUserNfts={setUserNfts}
              />
            ))
          : null}
      </div>

      {isShowBox && (
        <>
          <div className="flex justify-between flex-wrap w-full my-6">
            <div>Enter Recipient Wallet</div>
            <div className="flex justify-around">
              <span
                style={{ cursor: "pointer" }}
                className="text-white underline"
              >
                <a href="../coin.csv" download="../coin.csv">
                  Sample CSV
                </a>
              </span>
              <div>
                <label
                  htmlFor="inputTag"
                  style={{ cursor: "pointer" }}
                  className="border-0 text-white bg-teal-500 p-1 ml-2"
                >
                  Upload CSV
                  <input
                    id="inputTag"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      if (account === null) {
                        alert("connected wallet first");
                        return;
                      }

                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.onload = async ({ target }) => {
                        const csv = Papa.parse(target.result, {
                          header: true,
                        });
                        const parsedData = csv?.data;

                        setParsedData(parsedData);

                        setFileData(parsedData);

                        setShowBox(false);
                      };
                      reader.readAsText(file);
                    }}
                  />
                </label>
              </div>
            </div>
          </div>
          <form id="form" className="flex flex-col" onSubmit={onSubmitHandler}>
            <textarea
              name="data"
              id="data"
              rows={5}
              value={displayData}
              className="border-2 border-black bg-gray-700"
              onChange={(e) => setDisplayData(e.target.value)}
            ></textarea>
            <input
              type="submit"
              value="Submit"
              className="bg-teal-500 m-4 p-2 rounded-xl w-32 place-self-center text-xl text-white cursor-pointer hover:buttonB"
            />
          </form>
        </>
      )}

      {fileData !== null && !isShowBox && (
        <div className="flex flex-col justify-center">
          <table>
            <thead>
              <tr>
                <th>Recipient Wallet</th>
              </tr>
            </thead>
            <tbody>
              {fileData.map((item, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <p className="break-all">{item.receiver}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button
            className="bg-teal-500 m-4 px-8 rounded-xl  place-self-center text-xl text-white cursor-pointer hover:buttonB"
            onClick={() => setShowBox(true)}
          >
            Edit
          </button>
        </div>
      )}

      <div className="flex justify-center lg:justify-between items-center flex-wrap">
        <input
          type="text"
          placeholder="Pool Name"
          className="w-1/2 bg-gray-700"
          style={{
            margin: "20px 10px",
            padding: "10px",
          }}
          onChange={(e) => {
            set_pool_string(e.target.value);
          }}
        />
        <div className="flex justify-center items-center">
          <button
            className="text-white bg-teal-500 transition-transform hover:scale-110 hover:buttonB border-0"
            onClick={create_nft_pool}
          >
            CREATE
          </button>
        </div>
      </div>

      {pool_id !== null && (
        <div>
          <p className="break-all">Pools Id(Please copy it): {pool_id} </p>
        </div>
      )}
    </>
  );
};

const Collection1 = ({ collection, collectionIdx, userNfts, setUserNfts }) => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    let count = 0;
    userNfts[collectionIdx].nfts.forEach((nft) => {
      if (nft.isSelected) count++;
    });
    setSelected(count);
  }, [userNfts]);

  const selectAll1 = (isSelected) => {
    try {
      const newNfts = userNfts[collectionIdx].nfts.map((nft) => {
        return { ...nft, isSelected };
      });
      const newCollection = { ...collection, nfts: newNfts };
      const newUserNfts = [...userNfts];
      newUserNfts[collectionIdx] = newCollection;
      setUserNfts(newUserNfts);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="m-2">
      <div className="flex flex-wrap justify-between items-center">
        <h3 className="font-bold mb-1">Collection Name: {collection.name}</h3>
        <SelectBox sAmount={selected} selectAll={selectAll1} />
      </div>
      <div className="flex flex-wrap gap-2">
        {collection.nfts.map((nft, nftIdx) => (
          <NFT1
            key={nftIdx}
            nft={nft}
            collectionIdx={collectionIdx}
            nftIdx={nftIdx}
            userNfts={userNfts}
            setUserNfts={setUserNfts}
          />
        ))}
      </div>
    </div>
  );
};

const SelectBox = ({ sAmount, selectAll }) => {
  return (
    <div className="flex justify-end items-center">
      <h6>
        Selected NFTs: <span className="text-pink-600">{sAmount}</span>
      </h6>
      <div className="flex gap-2">
        <button
          className="border-0 text-white bg-teal-500 px-2 py-1 ml-2"
          onClick={() => selectAll(true)}
        >
          Select All
        </button>

        <button
          className="border-0 text-white bg-teal-500 px-2 py-1 ml-2"
          onClick={() => selectAll(false)}
        >
          Deselect All
        </button>
      </div>
    </div>
  );
};

const NFT1 = ({ nft, collectionIdx, nftIdx, userNfts, setUserNfts }) => {
  const selectNft1 = (collectionIdx, nftIdx) => {
    const newNfts = [...userNfts];
    newNfts[collectionIdx].nfts[nftIdx].isSelected =
      !newNfts[collectionIdx].nfts[nftIdx].isSelected;
    setUserNfts(newNfts);
  };

  return (
    <div
      className={`w-16 rounded-lg cursor-pointer ${
        nft.isSelected ? "bg-teal-500" : ""
      }`}
      onClick={() => {
        selectNft1(collectionIdx, nftIdx);
      }}
    >
      <img
        src={
          typeof nft.current_token_data.metadata_uri === "string"
            ? nft.current_token_data.metadata_uri
            : nft.current_token_data.metadata_uri.image
        }
        alt="nft"
        width="100%"
        className="rounded-full"
      />

      <div className="rounded-md text-center">
        <span> {nft.current_token_data.name}</span>
      </div>
    </div>
  );
};

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
