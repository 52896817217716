import { Box, Typography } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function Footer() {
  return (
    <Box
      className="footer bg-gradient-to-r from-black to-[#111729]"
      component="footer"
      sx={{
        gridArea: "footer",
        width: "100%",
        padding: "8px",
      }}
    >
      <Box
        color="white"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: { xs: "10px", sm: "10px 20px" },
        }}
      >
        <Typography variant={"h6"} textAlign="center">
          Web3Werx &reg; 2022
        </Typography>

        <Box>
          <GitHubIcon
            sx={{ cursor: "pointer" }}
            onClick={() => window.open("https://github.com/web3werx-team")}
          />
          <TwitterIcon
            sx={{ marginLeft: "15px", cursor: "pointer" }}
            onClick={() => window.open("https://twitter.com/web3werx")}
          />
        </Box>
      </Box>
    </Box>
  );
}
